/*
 * commmonObjects.ts
 * This is a place to declare objects that
 * are used globally between JS files
 * and need to be defined properly.
 *
 */

// worklist

/*
 *  based on
 *  WorklistModel > WorklistGroupSetting
 */
class worklistGroupSettingObj {
    public WorklistID: string = "";
    public FlexValue: number = 1;
    public Collapsed: boolean = false;
}

/*
 *  based on
 *  WorklistModel > WorklistGroup
 */
class WorklistGroupObj {
    public GroupName: string = "";
    public WorklistIds: Array<string> = new Array<string>();
}

// context panel
const USER_CREATED_CONTEXT_QUEUE_NAME = "QUEUE";
// static class
class ContextStudyType {
    static HISTORY: string = "HISTORY";
    static FOLDER: string = "FOLDER";
    static SHARED: string = "SHARED";
    static SEARCH: string = "SEARCH";
    static MYWORKLIST: string = "MYWORKLIST";
    static PROCEDURE: string = "PROCEDURE";
    static NONE: string = "NONE";
}
// lock it down
Object.freeze(ContextStudyType);

/*
 *  based on
 *  WorklistContextPaneViewModel >
 *  WorklistContextCheckedStudy
 */
class contextCheckedStudyObj {
    public ProcedureID: string = "";
    public WorklistID: string = "";
    public AsWorklistID: string = "";
    public PatientName = "";
    public ProcedureIDUnencrypted: number = 0;
    public IsQCWorklist: boolean = false;
    public StudyType: string = ContextStudyType.PROCEDURE;
}

/*
 *  based on
 *  WorklistContextPaneViewModel >
 *  WorklistContextQueueObject
 */
class contextPanelQueueObj {
    public SelectedStudies: Array<contextCheckedStudyObj> =
        new Array<contextCheckedStudyObj>();
    public WorklistID: string = "";
    public CriteriaID: string = USER_CREATED_CONTEXT_QUEUE_NAME;
    public AsWorklistID: string = "";
    public IsGeneratedQueue: boolean = false;
    public FirstOnly: boolean = false;
    public InitialSelectedProcedureID: string = "";
    public GeneratedSortOrder: string = "asc";
    public GeneratedSortName: string = "ProcedureDTTM";
    public IsQcWorklist: boolean = false;
    public RunInMultiMonitor: boolean = true;
    public SelectedStudyProcedureID: number;
    public FilteredByStat: boolean = false;
    public FilteredByAgeOut: boolean = false;
}

/*
 *  based on
 *  DataModels > ProcedureStatusCode
 */
class contextProcedureStatusCodeObj {
    public Code: string;
    public Description: string;
}

// selected study

class manualLaunchImageItem {
    public Text: string = "";
    public Value: number = 0;
    public Selected: boolean = false;
    public Description: string = "";
    public ImageSrc: string = "";
    public InstanceName: string = "";
}

// reporting

declare function addendReport(): void;
declare function reviseReport(): void;
declare function dictationPartialLoaded(): void;
declare function finalizedReport(procedureId: string): void;
declare function draftReport(procedureId: string): void;
declare function preliminaryReport(procedureId: string): void;
declare function processReport(procedureId: string): void;

class reportingModuleObj {
    public addendReport: Function;
    public processReport: Function;
    public dictationPartialLoaded: Function;
    public reviseReport: Function;
    public draftReport: Function;
    public preliminaryReport: Function;
    public finalizeReport: Function;
}

// integration framework

declare function getInstance(): any;

class integrationCollectionObj {
    public getInstance: Function;
}

class integrationObj {
    public Type: string;
    public InstanceName: string;
    public Category: string;
    public ConfigData: string;
}